import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div>
            <p>Created by <a target="blank" href="https://twitter.com/ChrisAbey3">@chrisabey</a></p>
        </div>
    );
}
export default Footer;