let compdict = {
    // abdulka: "https://i.imgur.com/4cpZrfU.jpeg",
    // abeych: "http://www.ellisbirdfarm.ca/uploads/2/4/0/2/24020064/welcome-chris-abey-2-web_orig.jpg",
    allenja: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Cleveland_Cavaliers_vs._Brooklyn_Nets_-_32170469167.jpg/1599px-Cleveland_Cavaliers_vs._Brooklyn_Nets_-_32170469167.jpg?20190216233048",
    allenra: "https://upload.wikimedia.org/wikipedia/commons/8/86/Ray_Allen_et_Doc_Rivers.jpg",
    antetgi: "https://upload.wikimedia.org/wikipedia/commons/4/40/Giannis_Antetokounmpo_vs_Washington_Wizards%2C_December_12th_2016.jpg",
    anthoca: "https://live.staticflickr.com/65535/51958690887_a726247322_k.jpg",
    // architi: "https://cdn.vox-cdn.com/thumbor/PBW2biGQTs8CN0vVTXNzWxq5A94=/0x0:2430x3600/1200x800/filters:focal(920x760:1308x1148)/cdn.vox-cdn.com/uploads/chorus_image/image/67059548/478993340.jpg.0.jpg",
    aytonde: "https://live.staticflickr.com/65535/51715569217_89bbdfde1e_4k.jpg",
    ballla: "https://live.staticflickr.com/65535/51916321346_4f0fff198d_k.jpg",
    balllo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Lonzo_Ball_%2849487881388%29.jpg/1599px-Lonzo_Ball_%2849487881388%29.jpg?20200206210842",
    barklch: "https://live.staticflickr.com/3027/2974753792_90d4e6049d_3k.jpg",
    barryri: "https://live.staticflickr.com/5220/5436870534_5dd10e310c_4k.jpg",
    // bayloel: "https://i.imgur.com/7SL7TZ8.jpeg",
    bealbr: "https://live.staticflickr.com/65535/48999460432_00660644a8_6k.jpg",
    birdla: "https://i.imgur.com/83hqmKv.jpeg",
    bookede: "https://live.staticflickr.com/65535/51916213540_8b7f9f5513_k.jpg",
    bryanko: "https://live.staticflickr.com/447/19508812260_2627d4a821_w.jpg",
    butleji: "https://live.staticflickr.com/65535/51914658962_ac684d3a79_k.jpg",
    capelcl: "https://live.staticflickr.com/65535/48999480412_373fffdee1_6k.jpg",
    // chambwi: "https://live.staticflickr.com/8088/8406720275_bfd04b888b_z.jpg",
    // cowenda: "https://a.espncdn.com/photo/2011/0816/espnBos_g_cowens_576.jpg",
    // cunnibi: "https://i.imgur.com/ffoztQA.jpeg",
    cunnica: "https://live.staticflickr.com/65535/51915806220_125b418e25_k.jpg",
    curryse: "https://live.staticflickr.com/65535/49515880103_60092a6dfb_5k.jpg",
    curryst: "https://upload.wikimedia.org/wikipedia/commons/4/40/Stephen_Curry_%2833140701266%29.jpg",
    // davisan: "https://i.imgur.com/Bs67MpL.jpeg",
    // debusda: "https://i.imgur.com/hjYbXe5.jpeg",
    doncilu: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Luka_Doncic_and_Dean_Wade%2C_Dallas_Mavericks_vs_Cleveland_Cavaliers_on_May_9%2C_2021.jpg/1599px-Luka_Doncic_and_Dean_Wade%2C_Dallas_Mavericks_vs_Cleveland_Cavaliers_on_May_9%2C_2021.jpg?20211130102206",
    derozde: "https://upload.wikimedia.org/wikipedia/commons/1/17/DeMar_DeRozan_%2825715910937%29.jpg",
    // drexlcl: "https://i.imgur.com/HhPENw2.gif",
    duncati: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Tim_Duncan_approaches_bench_Spurs-Magic078.jpg",
    duranke: "https://64.media.tumblr.com/504d6ad1da286454599122f023ce211d/tumblr_ofzxluhCAr1ruj0bpo1_1280.jpg",
    embiijo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Joel_Embiid_2018.jpg/1472px-Joel_Embiid_2018.jpg?20180226124748",
    ervinju: "https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2160,w_3200/https%3A%2F%2Fpippenainteasy.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F04%2F944368922.jpeg",
    // ewingpa: "https://i.imgur.com/y88b17k.jpeg",
    // fraziwa: "https://i.imgur.com/68yOIph.jpeg",
    garlada: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Darius_Garland_%2851176541423%29.jpg/1599px-Darius_Garland_%2851176541423%29.jpg?20210614131101",
    garneke: "https://upload.wikimedia.org/wikipedia/commons/7/77/Darius_Songaila_NBA_7.jpg",
    gervige: "https://live.staticflickr.com/5097/5473011239_b440e5dccb_k.jpg",
    giddejo: "https://live.staticflickr.com/65535/51915765025_28e608f513_k.jpg",
    goberru: "https://live.staticflickr.com/65535/51916297389_6fbcb9b805_k.jpg",
    gordoaa: "https://live.staticflickr.com/65535/49169395761_ba2096f376_4k.jpg",
    greendr: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/2019_nba_finals_game_2.jpg/1599px-2019_nba_finals_game_2.jpg?20190603090241",
    hardeja: "https://live.staticflickr.com/65535/48999249221_9cb57089c0_b.jpg",
    // hayesel: "https://cdn.nba.com/manage/2021/08/GettyImages-52407594.jpg",
    horfoal: "https://live.staticflickr.com/65535/51688498725_ff218f6d33_k.jpg",
    ingrabr: "https://live.staticflickr.com/65535/49487799403_ab3129ee8a_k.jpg",
    iversal: "https://live.staticflickr.com/2732/4187096470_983bfbe6d0_k.jpg",
    jamesle: "https://upload.wikimedia.org/wikipedia/commons/9/94/LeBron_James_%2815823062226%29.jpg",
    jokicni: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Nikola_Jokic_free_throw.jpg/1599px-Nikola_Jokic_free_throw.jpg?20200917153220",
    jordami: "https://live.staticflickr.com/8119/8645746456_9a1f13acca_z.jpg",
    kiddja: "https://live.staticflickr.com/7216/7214043968_0ad408f7fd_3k.jpg",
    kuzmaky: "https://live.staticflickr.com/65535/51646864420_96c5abc24d_4k.jpg",
    lavinza: "https://live.staticflickr.com/65535/51915248646_737d8005d1_k.jpg",
    leonaka: "https://upload.wikimedia.org/wikipedia/commons/6/66/Kawhi_Leonard_Dunk_%2833179280005%29.jpg",
    lillada: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Damian_Lillard_against_the_Cleveland_Cavaliers.jpg/1600px-Damian_Lillard_against_the_Cleveland_Cavaliers.jpg?20190118031658",
    malonka: "https://live.staticflickr.com/4052/4327285568_b8a127f023_b.jpg",
    // malonmo: "",
    maxeyty: "https://upload.wikimedia.org/wikipedia/commons/5/59/Washington_Wizards_guard_Spencer_Dinwiddie_shoots_the_ball_during_a_game_against_the_Philadelphia_76ers_%2851781889578%29.jpg",
    mccolcj: "https://live.staticflickr.com/65535/51657201412_6b92154a75_k.jpg",
    mobleev: "https://live.staticflickr.com/65535/51831957548_58d4c835cf_b.jpg",
    monkma: "https://live.staticflickr.com/65535/51959726918_c74f643d10_k.jpg",
    moranja: "https://live.staticflickr.com/65535/51915910464_2c4c7a2f5c_k.jpg",
    // maravpe: "https://i.imgur.com/RUZnByk.jpeg",
    // mcadobo: "https://cdn.nba.com/manage/2021/08/GettyImages-588141938-1568x882.jpg",
    // mchalke: "https://i.imgur.com/shWQh8D.jpeg",
    millere: "https://live.staticflickr.com/65535/51915265141_804dd051e5_k.jpg",
    mitchdo: "https://live.staticflickr.com/65535/49688061583_f08e500cfe_3k.jpg",
    // monroea: "https://a.espncdn.com/photo/2011/0309/ny_g_monroe_b1_576.jpg",
    // nashst: "https://cdn-wp.thesportsrush.com/2021/09/6c2081c7-untitled-design-2021-09-02t044719.675.jpg",
    nowitdi: "https://live.staticflickr.com/7376/11057608833_c3549d6090_5k.jpg",
    // olajuha: "https://i.ytimg.com/vi/TQN4Ckr4xaA/maxresdefault.jpg",
    // onealsh: "https://www.si.com/.image/t_share/MTg3Nzg1NjI5MDI1OTA0NTM0/si_shaq_50th_birthday_00005.jpg",
    // parisro: "https://news.cgtn.com/news/3355544d3541544d7767444f7a4d444d7a63444f31457a6333566d54/img/e692ad1e5d934ca7a2cecf18ceac9830/e692ad1e5d934ca7a2cecf18ceac9830.jpg",
    // paulch: "https://thecomeback.com/wp-content/uploads/2022/05/18231335-scaled.jpg",
    // paytoga: "https://c8.alamy.com/comp/DJE7KH/may-15-2004-los-angeles-ca-usa-lakers-gary-payton-looks-for-room-around-DJE7KH.jpg",
    piercpa: "https://upload.wikimedia.org/wikipedia/commons/a/af/Paul_Pierce_Caron_Butler.jpg",
    // pippesc: "https://cdn.nba.com/teams/legacy/www.nba.com/bulls/sites/bulls/files/pippen10greatestmoments.jpg",
    // roberos: "https://i.imgur.com/4E1mAtX.jpeg",
    // robinda: "https://i.ytimg.com/vi/TQN4Ckr4xaA/maxresdefault.jpg",
    // rodmade: "https://i.pinimg.com/originals/c9/68/89/c9688937e6c960690711fb87c721dafe.jpg",
    sextoco: "https://live.staticflickr.com/65535/49488182886_008c0b42ec_b.jpg",
    // stockjo: "https://img.bleacherreport.net/img/slides/photos/004/360/670/549a99aa3d7fbcd2436d9d9218567a5d_crop_exact.jpg?w=2975&h=2048&q=85",
    tatumja: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Jayson_Tatum_%2851686834897%29.jpg/1599px-Jayson_Tatum_%2851686834897%29.jpg?20211125235532",
    thomais: "https://images.complex.com/complex/images/c_fill,f_auto,g_center,w_1200/fl_lossy,pg_1/lkbavpkgyu1jjmqjetaq/isiah-thomas-michael-jordan",
    thompkl: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Jared_Dudley%2C_Klay_Thompson_%2824713429371%29.jpg",
    townska: "https://live.staticflickr.com/65535/51913741212_ac825de0f3_k.jpg",
    // unselwe: "https://s.hdnux.com/photos/01/12/25/35/19486554/3/rawImage.jpg",
    vanvlfr: "https://live.staticflickr.com/65535/50595810561_b827e14bf9_b.jpg",
    // wadedw: "https://allucanheat.com/wp-content/uploads/getty-images/2017/07/1077776370.jpeg",
    waltobi: "https://live.staticflickr.com/65535/51914485286_89508e785c_k.jpg",
    westbru: "https://live.staticflickr.com/65535/51959666851_0d56c2b87b_b.jpg",
    wiggian: "https://live.staticflickr.com/65535/51913768487_3494ccdad3_k.jpg",
    // wilkido: "https://cdn.nba.com/manage/2018/05/wilkins_bird_1988.jpg",
    willizi: "https://live.staticflickr.com/65535/49488363137_eabfd7951a_k.jpg",
    // worthja: "https://d1l5jyrrh5eluf.cloudfront.net/wp-content/uploads/2018/01/GettyImages-1319867581.jpg",
    youngtr: "https://live.staticflickr.com/65535/51916990390_e644763726_k.jpg"
  }; 
  export {compdict};