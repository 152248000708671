export const players = [
"Precious Achiuwa",
"Steven Adams",
"Bam Adebayo",
"Ochai Agbaji",
"Santi Aldama",
"Nickeil Alexander-Walker",
"Grayson Allen",
"Jarrett Allen",
"Jose Alvarado",
"Kyle Anderson",
"Giannis Antetokounmpo",
"Thanasis Antetokounmpo",
"Cole Anthony",
"OG Anunoby",
"Ryan Arcidiacono",
"Deni Avdija",
"Deandre Ayton",
"Udoka Azubuike",
"Marvin Bagley III",
"Amari Bailey",
"Patrick Baldwin Jr.",
"LaMelo Ball",
"Lonzo Ball",
"Mohamed Bamba",
"Paolo Banchero",
"Desmond Bane",
"Dalano Banton",
"Dominick Barlow",
"Harrison Barnes",
"Scottie Barnes",
"R.J. Barrett",
"Charles Bassey",
"Emoni Bates",
"Keita Bates-Diop",
"Nicolas Batum",
"Bradley Beal",
"Malik Beasley",
"MarJon Beauchamp",
"Charles Bediako",
"Davis Bertans",
"Patrick Beverley",
"Saddiq Bey",
"Goga Bitadze",
"Onuralp Bitim",
"Bismack Biyombo",
"Anthony Black",
"Leaky Black",
"Bogdan Bogdanovic",
"Bojan Bogdanovic",
"Bol Bol",
"Marques Bolden",
"Devin Booker",
"B.J. Boston Jr.",
"Chris Boucher",
"James Bouknight",
"Jamaree Bouyea",
"Malaki Branham",
"Christian Braun",
"Mikal Bridges",
"Miles Bridges",
"Oshae Brissett",
"Malcolm Brogdon",
"Armoni Brooks",
"Dillon Brooks",
"Greg Brown III",
"Jaylen Brown",
"Bruce Brown Jr.",
"Charlie Brown Jr.",
"Troy Brown Jr.",
"Kendall Brown",
"Kobe Brown",
"Moses Brown",
"Jalen Brunson",
"Thomas Bryant",
"Kobe Bufkin",
"Reggie Bullock",
"Alec Burks",
"Jared Butler",
"Jimmy Butler",
"John Butler",
"Jamal Cain",
"Kentavious Caldwell-Pope",
"Toumani Camara",
"Vlatko Cancar",
"Clint Capela",
"Jevon Carter",
"Wendell Carter Jr.",
"Alex Caruso",
"Colin Castleton",
"Malcolm Cazalon",
"Julian Champagnie",
"Max Christie",
"Josh Christopher",
"Sidy Cissoko",
"Jaylen Clark",
"Brandon Clarke",
"Jordan Clarkson",
"Nicolas Claxton",
"Noah Clowney",
"Amir Coffey",
"John Collins",
"Zach Collins",
"Mike Conley",
"Pat Connaughton",
"Bilal Coulibaly",
"Ricky Council IV",
"Robert Covington",
"Torrey Craig",
"Jae Crowder",
"Cade Cunningham",
"Seth Curry",
"Stephen Curry",
"Dyson Daniels",
"Anthony Davis",
"Johnny Davis",
"J.D. Davison",
"DeMar DeRozan",
"Dexter Dennis",
"Donte DiVincenzo",
"Moussa Diabate",
"Gradey Dick",
"Ousmane Dieng",
"Spencer Dinwiddie",
"Luka Doncic",
"Luguentz Dort",
"Ayo Dosunmu",
"Andre Drummond",
"Chris Duarte",
"Kris Dunn",
"Kevin Durant",
"Jalen Duren",
"Tari Eason",
"Anthony Edwards",
"Kessler Edwards",
"Keon Ellis",
"Joel Embiid",
"Drew Eubanks",
"Dante Exum",
"Bruno Fernando",
"Dorian Finney-Smith",
"Malachi Flynn",
"Simone Fontecchio",
"Jordan Ford",
"Trent Forrest",
"Evan Fournier",
"De'Aaron Fox",
"Javon Freeman-Liberty",
"Alex Fudge",
"Markelle Fultz",
"Daniel Gafford",
"Danilo Gallinari",
"Darius Garland",
"Usman Garuba",
"Luka Garza",
"Keyonte George",
"Paul George",
"Josh Giddey",
"Harry Giles",
"Shai Gilgeous-Alexander",
"Anthony Gill",
"Collin Gillespie",
"Jacob Gilyard",
"Rudy Gobert",
"Jordan Goodwin",
"Aaron Gordon",
"Eric Gordon",
"Devonte' Graham",
"Jerami Grant",
"A.J. Green",
"Draymond Green",
"Jalen Green",
"Jeff Green",
"Josh Green",
"A.J. Griffin Jr.",
"Quentin Grimes",
"Mouhamed Gueye",
"Rui Hachimura",
"Tyrese Haliburton",
"R.J. Hampton",
"Tim Hardaway Jr.",
"James Harden",
"Jaden Hardy",
"Ron Harper",
"Gary Harris",
"Joe Harris",
"Kevon Harris",
"Tobias Harris",
"Josh Hart",
"Isaiah Hartenstein",
"Sam Hauser",
"Jordan Hawkins",
"Jaxson Hayes",
"Killian Hayes",
"Gordon Hayward",
"Scoot Henderson",
"Taylor Hendricks",
"Tyler Herro",
"Buddy Hield",
"Haywood Highsmith",
"Nathaniel Hinton",
"D'Moi Hodge",
"Aaron Holiday",
"Jrue Holiday",
"Justin Holiday",
"Richaun Holmes",
"Chet Holmgren",
"Jalen Hood-Schifino",
"Al Horford",
"Talen Horton-Tucker",
"Danuel House",
"Caleb Houstan",
"Jett Howard",
"Kevin Huerter",
"James Huff",
"De'Andre Hunter",
"Bones Hyland",
"Joe Ingles",
"Brandon Ingram",
"Kyrie Irving",
"Jonathan Isaac",
"Jaden Ivey",
"G.G. Jackson",
"Isaiah Jackson",
"Andre Jackson Jr.",
"Jaren Jackson Jr.",
"Reggie Jackson",
"Trayce Jackson-Davis",
"LeBron James",
"Jaime Jaquez Jr.",
"DaQuan Jeffries",
"Ty Jerome",
"Isaiah Joe",
"Cameron Johnson",
"Jalen Johnson",
"Keldon Johnson",
"Keon Johnson",
"Keyontae Johnson",
"Nikola Jokic",
"Colby Jones",
"Damian Jones",
"Derrick Jones",
"Herb Jones",
"Tre Jones",
"Tyus Jones",
"DeAndre Jordan",
"Cory Joseph",
"Nikola Jovic",
"Johnny Juzang",
"Luke Kennard",
"Walker Kessler",
"Braxton Key",
"Corey Kispert",
"Maxi Kleber",
"Nathan Knight",
"Kevin Knox",
"Christian Koloko",
"John Konchar",
"Furkan Korkmaz",
"Luke Kornet",
"Jonathan Kuminga",
"Kyle Kuzma",
"Jake LaRavia",
"Zach LaVine",
"Jock Landale",
"A.J. Lawson",
"Caris LeVert",
"Damion Lee",
"Saben Lee",
"Alex Len",
"Kawhi Leonard",
"Kira Lewis Jr.",
"Justin Lewis",
"Maxwell Lewis",
"E.J. Liddell",
"Damian Lillard",
"Nassir Little",
"Dereck Lively II",
"Isaiah Livers",
"Chris Livingston",
"Kenny Lofton Jr.",
"Kevon Looney",
"Brook Lopez",
"Robin Lopez",
"Kevin Love",
"Kyle Lowry",
"Seth Lundy",
"Trey Lyles",
"Theo Maledon",
"Sandro Mamukelashvili",
"Terance Mann",
"Tre Mann",
"Boban Marjanovic",
"Lauri Markkanen",
"Naji Marshall",
"Caleb Martin",
"Cody Martin",
"K.J. Martin",
"Garrison Mathews",
"Bennedict Mathurin",
"Wesley Matthews",
"Tyrese Maxey",
"Skylar Mays",
"Miles McBride",
"C.J. McCollum",
"T.J. McConnell",
"Jaden McDaniels",
"Jalen McDaniels",
"Doug McDermott",
"JaVale McGee",
"Bryce McGowens",
"Jordan McLaughlin",
"De'Anthony Melton",
"Sam Merrill",
"Chimezie Metu",
"Vasilije Micic",
"Khris Middleton",
"Brandon Miller",
"Jordan Miller",
"Leonard Miller",
"Patty Mills",
"Shake Milton",
"Justin Minaya",
"Josh Minott",
"Davion Mitchell",
"Donovan Mitchell",
"Evan Mobley",
"Isaiah Mobley",
"Malik Monk",
"Moses Moody",
"Xavier Moon",
"Wendell Moore Jr.",
"Ja Morant",
"Marcus Morris",
"Markieff Morris",
"Monte Morris",
"Trey Murphy III",
"Dejounte Murray",
"Jamal Murray",
"Keegan Murray",
"Kris Murray",
"Mike Muscala",
"Svi Mykhailiuk",
"Larry Nance Jr.",
"Andrew Nembhard",
"Aaron Nesmith",
"Georges Niang",
"Daishen Nix",
"Zeke Nnaji",
"Miles Norris",
"Markquis Nowell",
"Frank Ntilikina",
"Jusuf Nurkic",
"Jordan Nwora",
"Royce O'Neale",
"Chuma Okeke",
"Josh Okogie",
"Onyeka Okongwu",
"Isaac Okoro",
"Victor Oladipo",
"Kelly Olynyk",
"Eugene Omoruyi",
"Cedi Osman",
"Kelly Oubre Jr.",
"Chris Paul",
"Cameron Payne",
"Gary Payton II",
"Filip Petrusev",
"Julian Phillips",
"Jalen Pickett",
"Mason Plumlee",
"Brandin Podziemski",
"Jakob Poeltl",
"Aleksej Pokusevski",
"Jordan Poole",
"Craig Porter Jr.",
"Michael Porter Jr.",
"Otto Porter",
"Bobby Portis",
"Kristaps Porzingis",
"Micah Potter",
"Dwight Powell",
"Norman Powell",
"Joshua Primo",
"Taurean Prince",
"Payton Pritchard",
"Olivier-Maxence Prosper",
"Trevelin Queen",
"Neemias Queta",
"Immanuel Quickley",
"Lester Quinones",
"Julius Randle",
"Duop Reath",
"Austin Reaves",
"Cam Reddish",
"Paul Reed Jr.",
"Naz Reid",
"Jared Rhoden",
"Sir'Jabari Rice",
"Nick Richards",
"Josh Richardson",
"Duncan Robinson",
"Jerome Robinson",
"Mitchell Robinson",
"Orlando Robinson",
"Jeremiah Robinson-Earl",
"David Roddy",
"Ryan Rollins",
"Derrick Rose",
"Terry Rozier",
"Ricky Rubio",
"Rayan Rupert",
"D'Angelo Russell",
"Matt Ryan",
"Domantas Sabonis",
"Luka Samanic",
"Jermaine Samuels",
"Adama Sanogo",
"Gui Santos",
"Dario Saric",
"Olivier Sarr",
"Marcus Sasser",
"Admiral Schofield",
"Dennis Schroder",
"Dereon Seabron",
"Alperen Sengun",
"Brice Sensabaugh",
"Collin Sexton",
"Landry Shamet",
"Day'Ron Sharpe",
"Shaedon Sharpe",
"Ben Sheppard",
"Pascal Siakam",
"Ben Simmons",
"Anfernee Simons",
"Jericho Sims",
"Jalen Slawson",
"Javonte Smart",
"Marcus Smart",
"Dennis Smith",
"Dru Smith",
"Ish Smith",
"Jalen Smith",
"Jabari Smith Jr.",
"Nick Smith Jr.",
"Terquavion Smith",
"Jeremy Sochan",
"Jaden Springer",
"Lamar Stevens",
"Isaiah Stewart II",
"Julian Strawther",
"Max Strus",
"Jalen Suggs",
"Cole Swider",
"Jae'sean Tate",
"Jayson Tatum",
"Terry Taylor",
"Garrett Temple",
"Dalen Terry",
"Daniel Theis",
"Cam Thomas",
"Amen Thompson",
"Ausar Thompson",
"Klay Thompson",
"Tristan Thompson",
"J.T. Thor",
"Matisse Thybulle",
"Xavier Tillman, Sr.",
"Jacob Toppin",
"Obi Toppin",
"Karl-Anthony Towns",
"Gary Trent Jr.",
"Oscar Tshiebwe",
"P.J. Tucker",
"Myles Turner",
"Hunter Tyson",
"Stanley Umude",
"Jonas Valanciunas",
"Fred VanVleet",
"Jarred Vanderbilt",
"Devin Vassell",
"Sasha Vezenkov",
"Gabe Vincent",
"Nikola Vucevic",
"Dean Wade",
"Franz Wagner",
"Moe Wagner",
"Ish Wainright",
"Jabari Walker",
"Jarace Walker",
"Lonnie Walker",
"Cason Wallace",
"Jordan Walsh",
"Duane Washington Jr.",
"TyTy Washington Jr.",
"P.J. Washington",
"Yuta Watanabe",
"Lindy Waters III",
"Trendon Watford",
"Peyton Watson",
"Victor Wembanyama",
"Blake Wesley",
"Russell Westbrook",
"Coby White",
"Derrick White",
"Dariq Whitehead",
"Cam Whitmore",
"Aaron Wiggins",
"Andrew Wiggins",
"Lindell Wigginton",
"Grant Williams",
"Jalen Williams",
"Jaylin Williams",
"Nate Williams Jr.",
"Vincent Williams Jr.",
"Kenrich Williams",
"Mark Williams",
"Patrick Williams",
"Robert Williams",
"Ziaire Williams",
"Zion Williamson",
"Jalen Wilson",
"Dylan Windler",
"James Wiseman",
"Isaiah Wong",
"Christian Wood",
"Delon Wright",
"Thaddeus Young",
"Trae Young",
"Omer Yurtseven",
"Cody Zeller",
"Ivica Zubac",
];
